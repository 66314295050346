@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap");

// colors
$black: #000;
$white: #fff;

$color-dark: #333;
$color-gray: #828181;
$color-light-gray: #b6b4b4;
$color-lght-gray-box: #d3d2d2;
$color-extra-light-gray: #eee;
$color-light: #f9ddb6;
$color-green-dark: #3f5e2b;

$color-cta: #f0b625;
$color-cta-light: #cd9b5b;
$color-orange-light: #f9eae3;

$green: #50d214;
$green-light: #dcf6d0;
$green-dark: #205408;
$green-ultra-light: #edfdff;

$red: #d2142d;
$red-light: #f7cecc;
$red-dark: #540820;

$color-orange-dark: #922600;
$orange-light: #f6dcd0;
$orange-dark: #542008;

$color-yellow: #ffd55a;

$color-purple: #eddaff;

// fonts
$font-size: 17px;
$font-family: "Roboto", sans-serif;
$font-small: 0.9em;
$font-line-height: 1.5;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-size-label: 15px;
$font-small: 0.9em;
$font-small-header: 1.2em;
$font-line-height: 1.5;

// box size
$margin: 1em;
$margin-large: 1.5em;
$padding: 1em;
$padding-large: 1.5em;
$border-radius: 0.2em;

$grid-medium-breakpoint: 820px !default; // Medium screen breakpoint for grid.
$grid-large-breakpoint: 1280px !default; // Large screen breakpoint for grid.

//paddings
$padding-top: 2em;
$padding-left: 2.5em;
$padding-bottom: 2em;
$padding-right: 1em;
