@import "./variables";

.ui.cards > .ui.card > .card-overlay {
    height: 100%;
    width: 100%;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    cursor: pointer;

    .suggested-label {
        left: calc(-0rem - 1.2em) !important;
        animation-duration: 5s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background-color: $color-cta;
        background-image: linear-gradient(315deg, $color-cta 0%, #ffdd00 64%, $color-cta 100%);
        color: $color-dark;
    }
}

.ui.card > .card-overlay {
    height: 100%;
    width: 100%;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    cursor: pointer;

    .suggested-label {
        animation-duration: 5s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background-color: $color-cta;
        background-image: linear-gradient(315deg, $color-cta 0%, #ffdd00 64%, $color-cta 100%);
        color: $color-dark;
    }
}

.ui.cards .ui.card.plan-card {
    z-index: 0;
    border-radius: 10px;

    .card-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .card-image-section {
        img {
            width: 180px;
            height: 180px;
        }
    }

    .card-data-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 20px;

        .description {
            text-align: center;
            color: $color-green-dark;

            .oldPrice {
                color: $red;
                text-decoration: line-through;
                margin-right: 1em;
            }
        }

        .card-subheader {
            min-height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &:hover {
        text-decoration: none;
        background-color: $color-light;
    }

    &.selected-plan {
        background-color: $color-light !important;
        border: 1px solid $color-cta;
    }
}

.ui.card.plan-card.suggested-box {
    border: 1px solid $color-cta;
}

.ui.cards .ui.card.order-card {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
    margin: 0;

    .content {
        padding: 0 0.8em;

        .order-card-header {
            display: flex;
            align-items: center;
            position: relative;

            .ui.header {
                margin: 0.5em 0.2em;
                font-size: 1.4em;
                width: 75%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .ui.button {
                background-color: transparent;
                //border: 1px solid $color-green-dark;
                color: $color-green-dark;
                width: 2.5em;
                height: 2.5em;
                padding: 0;
            }

            span {
                position: absolute;
                right: 0;
                font-size: 1.5em;
            }
        }

        .description {
            font-size: 1.2em;
        }

        .meta {
            font-size: 1.2em;
        }
    }

    &:last-child {
        margin-bottom: 2em;
    }
}

.ui.cards > .ui.card.month-box-card {
    border-radius: 10px;
    position: relative;

    .indicator {
        width: 0.8em;
        height: 0.8em;
        background-color: #d2142d;
        position: absolute;
        z-index: 1;
        right: 0.8em;
        top: 0.8em;
        border-radius: 50%;
    }

    .month-box-card-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: $padding 0;

        .image {
            width: 30%;
            height: 100%;

            img {
                width: 90px;
            }
        }

        .image.upgrade {
            width: 25%;
            height: 100%;

            img {
                width: 60px;
            }
        }

        .info {
            width: 70%;
            height: 100%;

            h3 {
                margin-bottom: 0.2em;
            }

            .meta {
                font-size: 1.1em;
            }

            .description {
                text-decoration: underline;
                font-size: 1.2em;
            }
        }
    }
}

.ui.card.settings-card {
    border-radius: 10px;

    .content.header-container {
        display: flex;
        justify-content: space-between;

        .header {
            width: 95%;
        }

        i {
            width: 5%;
            color: $color-light-gray;
        }
    }

    .content {
        .info-container {
            display: flex;
            align-items: center;

            p {
                margin-bottom: 0.5em;
                font-size: 1.2em;
            }

            .info-title {
                width: 40%;
            }

            .info-data {
                color: $color-light-gray;
                width: 60%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .info-data.link {
                color: $black;
                text-decoration: underline;
                cursor: pointer;
            }

            .info-data.link.red-color {
                color: $red;
                text-decoration: underline;
            }

            .info-data.link.green-color {
                color: $color-green-dark;
                text-decoration: underline;
            }
        }
    }

    &.cards-together {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: 0;
    }

    &.benefits {
        padding: 2.5em 1em;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: 0;

        .header {
            font-size: 1.2em;
        }
    }
}

.ui.cards > .ui.card.addOn-card {
    margin: $padding;

    .content {
        &.improve-opacity {
            opacity: 0.6;
        }

        .card-sell-content {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .image-section {
                cursor: pointer;
                width: 20%;
                padding: 0.5em $padding;
            }

            .info-section {
                width: 62%;

                .header-section {
                    display: flex;
                    align-items: flex-start;
                    height: auto;
                    flex-direction: column;

                    .ui.header {
                        margin: 0;
                        padding-right: $padding;
                    }

                    .icon {
                        margin-left: 10px;
                        background-color: $color-green-dark;
                        color: $white;
                    }

                    p {
                        margin: 5px 0;
                    }
                }

                .price-info {
                    margin-top: $padding;
                    p {
                        margin: 5px 0;
                    }
                }
            }

            .quantity-section {
                width: 18%;

                .ui.selection.dropdown {
                    min-width: 100%;
                    border: 1px solid rgba(34, 36, 38, 0.15);
                    box-shadow: none;
                    z-index: 1;

                    &:focus {
                        border: 1px solid $color-cta;
                    }

                    &.active {
                        input {
                            border: 1px solid $color-cta;
                        }
                    }
                }
            }
        }
    }

    &.selected {
        border: 1px solid $color-cta;
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -600px 0;
    }
    100% {
        background-position: 600px 0;
    }
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
    .ui.cards .ui.card.plan-card {
        width: 95%;

        .card-content {
            flex-direction: row;
        }

        &:hover {
            background-color: $white;
        }
    }

    .ui.cards {
        .ui.card.order-card {
            .content {
                .order-card-header {
                    .ui.header {
                        font-size: 1.25em;
                    }
                    span {
                        font-size: 1.25em;
                    }
                }
            }
        }

        .ui.card.month-box-card {
            .month-box-card-container {
                .info {
                    margin-left: 10px;
                }
            }
        }
    }
}

.donate-box-card {
    flex: 0 0 auto;
    margin: 0 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    height: auto;
    width: 18em;

    .image-container {
        height: 12em;
        width: 100%;
        background-color: #eee;

        .image {
            height: 100%;
            width: 100%;
        }
    }

    .box-name {
        text-align: left;
        font-size: 1.15em;
        color: $color-light-gray;
        text-transform: uppercase;
        width: 100%;
        padding: 5px 0;
        margin-top: 0;
    }
}

.donate-box-card:first-child {
    margin-left: 0;
}

@media only screen and (max-width: 360px) and (orientation: portrait) {
    .ui.cards {
        .ui.card.order-card {
            .content {
                .order-card-header {
                    .ui.header {
                        font-size: 1.1em;
                    }
                    span {
                        font-size: 1.2em;
                    }
                }
            }
        }
    }
}
