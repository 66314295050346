@import "./variables";

.dropdown-container {
    margin-bottom: 2em;
    position: relative;

    .ui.label {
        background-color: $white;
        padding: 0;
        margin: 10px 0;
        font-size: $font-size-label;
        font-weight: 500;
    }

    .ui.label.floating {
        top: -0.5em;
        left: 2em;
        padding: 0 0.5em;
        z-index: 1;
    }

    .ui.dropdown {
        border-radius: 8px;
        font-size: $font-size;
        padding-right: 0.5em;
        position: relative;
        min-height: 3em;
        display: flex;
        align-items: center;

        .icon.chevron {
            position: absolute;
            right: 10px;
            top: 15px;
        }

        .default {
            color: $black !important;
            opacity: 0.5;
        }

        .label {
            background: $color-light;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .delete {
                color: $red;
            }
        }
    }
}

.dropdown-box-container {
    margin-bottom: 2em;
    position: relative;

    .menu {
        width: 100%;
        .item {
            display: flex !important;
            justify-content: space-around !important;
            align-items: center !important;
            .ui.avatar {
                height: 10em;
                max-height: 10em !important;
            }

            .text {
                font-size: 1.4em;
            }
        }
    }
}

.dropdown-container.no-margin {
    margin: 0;
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
    .dropdown-container {
        .ui.dropdown {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .label {
                width: 70%;
            }
        }
    }
}
