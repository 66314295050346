@import "./variables";

.ui.grid {
    margin: 0;
}

.container-padding {
    padding: 0 25%;
}

.container-padding-small {
    padding: 0 12%;
}

.button-container-padding {
    padding: 0 35% 2em 35%;
}

.container-padding.show-computer {
    padding: 0;
}

.container-padding.show-mobile {
    padding: 0;
}

.container-padding.padding-bottom {
    padding-bottom: $padding-bottom;
}

.manage-subscription-container {
    padding: 0 $padding;
}

@media only screen and (max-width: 768px) {
    .container-padding {
        padding: 0;
    }

    .container-padding-small {
        padding: 0;
    }

    .manage-subscription-container {
        padding: 0;
    }

    .button-container-padding {
        padding: 0 $padding $padding $padding;
    }
}

@media only screen and (width: 768px) and (height: 1024px) {
    .container-padding {
        padding: 0;
    }

    .button-container-padding {
        padding: 0;
    }

    .manage-subscription-container {
        padding: 0;
    }
}

@media only screen and (min-width: 1024px) and (min-height: 768px) {
    .ui.grid.grid.grid > .row > [class*="large screen only"].column:not(.mobile),
    .ui.grid.grid.grid > [class*="large screen only"].column:not(.mobile),
    .ui.grid.grid.grid > [class*="large screen only"].row:not(.mobile),
    .ui[class*="large screen only"].grid.grid.grid:not(.mobile) {
        display: block !important;
    }
}
