@import "./src/scss/components/core";
@import "./src/scss/components/variables";
@import "./src/scss/components/utilities";
@import "./src/scss/components/layout";

.manage-subscription-container {
    .box-sections {
        margin: 1em 0 2em 0;

        .section-title {
            color: $color-cta;
            text-transform: capitalize;
            padding: 0.25em 0;
            font-size: 1.2em;
            border-radius: 10px;
        }
    }

    .select-subscription {
        padding: $padding;
        background: $color-green-dark;
        border-radius: 10px;

        .select-subscription-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                color: $white;
                margin: 0 0 1em 0;
            }
        }
    }

    .next-payment {
        text-align: center;
        color: $color-light-gray;
    }

    .input-pdf-container {
        width: 100%;
        min-height: 40vh;
        border-radius: 10px;

        .custom-pdf-viewer {
            overflow: auto;

            div {
                canvas {
                    box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
                    margin: 1em 0;
                }
            }
        }
    }

    .remaining-boxes-content {
        padding: 1em 0;
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
        height: auto;
    }

    .recoveringCard {
        border: 1px solid #eee;
        margin: $margin 0;
        border-radius: 10px;

        .title {
            border-bottom: 1px solid #eee;
            padding: $padding;
        }

        .text {
            padding: $padding;
        }

        .radio-actions {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
        }

        .action {
            padding: $padding 14em;
        }
    }

    .ui.grid.user-information-grid {
        .ui.list {
            .item {
                padding: 10px 0;
                .header {
                    margin-bottom: 10px;
                }

                .special-content {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .ui.button {
                        width: 100px;
                        color: $color-cta;
                        border: 1px solid $color-cta;
                        background-color: $white;

                        &:hover {
                            background-color: $color-cta;
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    .subscription-grid {
        .row {
            .column {
                .ui.table {
                    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
                    border-radius: 10px;
                }
            }
        }
    }
}

.box-image-section {
    position: relative;
    margin-bottom: 10px;

    .ui.image.upgrade-image {
        position: absolute;
        top: -60px;
        right: 0;
        width: 100px;
    }
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
    .ui.container {
        div > div {
            .menu {
                .item {
                    font-size: 10px;
                }
            }
        }
    }

    .manage-subscription-container {
        padding: 0;

        .recoveringCard {
            .action {
                padding: $padding 3em;
            }
        }
    }

    .ui.grid.subscription-grid {
        margin-top: -1rem;
        margin-bottom: -1rem;
        margin-left: auto;
        margin-right: auto;

        .column {
            padding: 0;
        }
    }
}

.cancel-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    .separator {
        font-size: 1.2em;
        font-weight: bold;
    }
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
    .cancel-buttons-container {
        flex-direction: column;
    }
}

@media only screen and (max-width: 320px) and (orientation: portrait) {
    .manage-subscription-container {
        .input-pdf-container {
            height: 25vh;
        }
    }
}
