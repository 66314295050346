@import "./variables";
@import "./utilities";

.sticky-top {
    position: sticky;
    background-color: white;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    box-shadow: -2px -2px 5px 1px $color-light-gray;

    .navbar {
        height: 5.5em;
        width: 100%;
        display: flex;
        padding: 1em;
        justify-content: space-between;
        align-items: center;

        .center-logo {
            width: 15%;
        }

        .route-container {
            width: 75%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .step-information {
                margin: 0;
                font-size: $font-size;
                margin-right: $margin;
            }

            .help-chat {
                background: transparent;
                border: none;
                outline: none;
                cursor: pointer;
            }

            .help-chat:hover {
                text-decoration: none;
            }

            .support {
                background: url("../../images/support-green.svg") !important;
                background-position: top left !important;
                background-size: 24px !important;
                background-repeat: no-repeat !important;
                padding-left: 28px !important;
            }
        }

        .log-out {
            width: 10%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@media only screen and (max-width: 719px) {
    .sticky-top {
        z-index: 2000;

        img {
            width: 55px !important;
        }

        section {
            .mini-top-banner {
                height: 66px;
                h1 {
                    font-size: 19px;
                }
            }
        }

        .navbar {
            padding: 0 0.5em;
            height: 66px;

            .center-logo {
                width: 15%;
                padding: 0;
            }

            .route-container {
                width: 55%;
                justify-content: center;

                .step-information {
                    margin: 0;
                }
            }

            .log-out {
                width: 30%;
            }
        }
    }
}

@media only screen and (width: 768px) and (height: 1024px) {
    .sticky-top {
        img {
            width: 55px !important;
        }
    }
}
