@import "./src/scss/components/core";
@import "./src/scss/components/variables";
@import "./src/scss/components/utilities";
@import "./src/scss/components/layout";

.input-container {
    .multi-input-line {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .input-half {
            width: 48%;
        }

        .input-longer {
            width: 56%;
        }

        .input-shorter {
            width: 40%;
        }
    }

    .successful-note {
        font-size: 1.4em;
        text-align: center;
    }
}

.input-container-half {
    .multi-input-line {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .input-half {
            width: 48%;
        }

        .input-longer {
            width: 56%;
        }

        .input-shorter {
            width: 40%;
        }
    }
}

.cards-container {
    width: 100%;
}

.cards-container.upgrade-boxes {
    margin-top: 3em;
}

.checkbox-container {
    margin: 2em 0;
    text-align: center;
}

.terms-links-container {
    text-align: center;
    font-size: 0.9em;

    a {
        text-decoration: underline;
    }
}

.order-summary-container-customized{
    margin-top:30%;
}

.order-summary-container {
    padding: 0 1em;
    background-color: $color-extra-light-gray;    
    &.addons {
        position: sticky;
        top: 5em;
    }

    .summary-item {
        padding: $padding 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            font-size: 1.5em;
            margin: 0;
            color: $black;
        }

        a {
            font-size: 1.5em;
            margin: 0;
            color: $black;
        }

        .bold {
            font-weight: bold;
        }

        .total {
            font-size: 1.6em;
        }

        span {
            font-size: 1.5em;
            color: $color-green-dark;
        }
    }

    .promo-input {
        padding: 0 6em;
    }
}

.icons-section {
    padding: $padding;
    min-height: 10em;
    background-color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ui.container.payment-container {
    .ui.grid.payment-grid {
        margin-left: 0;
        margin-right: 0;

        .row > .column > .step-six-form {
            padding: 0;

            .ui.accordion {
                .title {
                    padding: 0;
                    border-radius: 15px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;

                    i {
                        display: none;
                    }

                    .ui.header {
                        margin: 0;
                        padding: $padding 0;
                        text-decoration: underline;
                        color: $color-cta;
                    }

                    &.active {
                        .ui.header {
                            color: $black;
                        }
                        border: 1px solid $color-extra-light-gray;
                        border-bottom: none;
                    }
                }

                .content {
                    padding: 0 $padding;
                    border-radius: 15px;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    &.active {
                        border: 1px solid $color-extra-light-gray;
                        border-top: none;
                    }
                }
            }
        }
    }
}

.show-mobile {
    display: none;
}

.show-computer {
    display: flex;
    flex-direction: column;
}

.input-errors {
    text-align: center;
    color: $red;
    margin-bottom: 1em !important;
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
    .show-mobile {
        display: flex;
        flex-direction: column;
    }

    .show-computer {
        display: none;
    }

    .suscribe-text {
        padding: 0 5px;
    }

    .checkbox-container {
        text-align: left;
    }

    .terms-links-container {
        font-size: 0.8em;
    }

    .order-summary-container {
        margin-top: 2em;
        padding: 0 1em;
    }

    .ui.container.payment-container {
        margin: 0 !important;

        .ui.grid.payment-grid {
            .column {
                padding: 0;
            }
        }
    }

    .input-container {
        padding: 0;

        .successful-note {
            font-size: 1.2em;
        }
    }

    .icons-section {
        .ui.image {
            width: 115px;
        }
    }
}

.hand-address-link {
    color: $red !important;
    outline: none;
    text-decoration: underline;
    cursor: pointer;
}

.input-content .ui.input > input {
    border-radius: 0px;
}

.ui.container > .padding-page {
    .ui.grid {
        margin: 0;

        .row > .column {
            .column-info-container {
                margin: $padding 0;
                box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
                padding: 4em 2em;
                border-radius: 10px;
                min-height: 24vw;

                ul > li {
                    list-style: disc;
                    margin: $padding;
                    font-size: 1.2em;
                }
            }

            .custom-accordion {
                border-radius: 10px;
            }

            .survival-garden-background {
                margin: $padding 0;
                box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
                border-radius: 10px;
                min-height: 24vw;

                .ui.image {
                    height: auto;
                    border-radius: 10px;
                }
            }

            .ui.grid > .row {
                padding: 4em 2em;
                .column {
                    .feature-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
    .cards-container {
        padding-left: $padding;
        padding-right: $padding;
    }
    .order-summary-container-customized{
        margin-top: 48%;
    }
}

@media only screen and (max-width: 320px) and (orientation: portrait) {
    .icons-section {
        .ui.image {
            width: 90px;
        }
    }
}
