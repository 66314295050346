@import "./variables";

.sub-header-container {
    min-height: 5em;

    &.sub-header-padding-small {
        .sub-header {
            padding: 1.5em 0 3em 0;
        }
    }

    .sub-header {
        padding: 3em 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;

        .user-info {
            width: 100%;
            text-align: right;
            font-size: $font-small;

            .ui.button {
                background-color: $white;

                .icon {
                    background-color: $white;
                }
            }
        }
        .back-arrow-customized{
            font-size:25px;
        }
        .ui.button.back-arrow {
            position: absolute;
            background: transparent;            
            border: none;
            outline: none;
            left: -0.5em;
            width: 5em;
            height: 3em;
            top: 2em;
            padding: 0;
            margin: 0;
        }

        .ui.button.back-close {
            position: absolute;
            background: transparent;
            border: none;
            outline: none;
            right: 0;
            width: 2em;
            height: 2em;
            top: 2em;
            padding: 0;
            margin: 0;
        }

        .ui.header.suscribe-title {
            font-size: 1.8em;
            font-weight: bold;
        }

        .ui.header.box-title {
            font-size: 1.6em !important;
            font-weight: bold;
        }        

        .suscribe-text {
            text-align: center;
            padding-bottom: 0;
            margin-top: 0.5em;
            font-size: 1.2em;
        }

        .suscribe-text.box-title {
            color: $color-cta;
            text-transform: uppercase;
            font-weight: bold;
        }
    }

    .ui.divider {
        margin-top: 0;
    }

    .ui.header.subtitle {
        font-size: 1.3em;
        margin: 1.5em 0 3em 0;
    }
}

.sub-header-container.short-padding {
    padding: 3em 0;
}

.box-sub-header-container {
    .sub-header {
        padding: 2em 0 0 0;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        position: relative;

        .ui.button.back-close {
            position: absolute;
            background: transparent;
            border: none;
            outline: none;
            right: -0.5em;
            width: 2em;
            height: 2em;
            top: 1.2em;
            padding: 0;
            margin: 0;
        }
    }

    .ui.divider {
        margin-bottom: 2em;
    }
}

.box-title-customized{
    font-size: 22px !important;
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
    .sub-header-container,
    .sub-header-container.short-padding {
        padding: 0;
    }
    .box-sub-header-container {        
    .sub-header {
        .ui.button.back-close {
            position: absolute;
            background: transparent;
            border: none;
            outline: none;
            right: -0.5em;
            width: 2em;
            height: 2em;
            top: 1.2em;
            padding: 0;
            margin: 0;
            font-size:18px !important;
        }
    }
    }
}

@media only screen and (max-width: 550px) {
    .ui.header.subscribe-title {
        font-size: 2em !important;
    }
}