@import "./variables";

.ui.modals > .ui.modal.error-modal {
    border-radius: 10px;
    padding-bottom: 2em;

    .icon {
        color: $color-green-dark;
    }

    .ui.image {
        width: 120px;
    }

    p {
        font-size: 1.2em;
        text-align: center;
    }

    .actions {
        background-color: $white;
        border: none;

        .action-buttons-container {
            display: flex;
            justify-content: space-evenly;

            .action-buttons-content {
                width: 40%;
            }
        }
    }
}

.ui.modals > .ui.modal.confirm-modal {
    border-radius: 10px;
    padding-bottom: 2em;

    .icon {
        color: $color-green-dark;
    }

    .content {
        .ui.image {
            width: 100px;
        }

        .ui.header {
            margin-top: 0;
        }

        .create-subscription {
            strong {
                color: $color-green-dark;
            }
        }
    }

    .actions {
        background-color: $white;
        border: none;

        .action-buttons-container {
            display: flex;
            justify-content: space-evenly;

            .action-buttons-content {
                width: 40%;
            }
        }

        .action-buttons-container.hide-action-buttons {
            display: none;
        }
    }

    p {
        font-size: 1.2em;
        text-align: center;
    }
}

.ui.modals > .ui.modal.confirm-addon-modal {
    border-radius: 10px;
    padding-bottom: 2em;

    .icon {
        color: $color-green-dark;
    }

    .content {
        .ui.image {
            width: 100px;
        }

        .ui.header {
            margin-top: 0;
        }

        .create-subscription {
            strong {
                color: $color-green-dark;
            }
        }

        .message-section {
            padding: 2em;

            .message-text {
                font-size: 1.2em;
            }
            ul {
                li {
                    font-size: 1.2em;
                    list-style-type: circle;
                }
            }
        }
    }

    .actions {
        background-color: $white;
        border: none;

        .action-buttons-container {
            display: flex;
            justify-content: space-evenly;

            .action-buttons-content {
                width: 40%;
            }
        }
    }

    p {
        font-size: 1em;
    }
}

.ui.modals > .ui.modal.pause-subscription-modal {
    border-radius: 0;
    padding-bottom: 2em;

    .icon {
        color: $color-green-dark;
    }

    .content {
        padding-bottom: 0;

        .ui.header {
            margin-top: 0;
            font-size: 3.4em;
        }

        .recovering-card {
            border-radius: 10px;

            .title {
                border-bottom: 1px solid #eee;
                padding: $padding;
            }

            .text {
                padding: $padding;
                text-align: justify;
            }

            .radio-actions {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
            }
        }
    }

    .actions {
        background-color: $white;
        border: none;

        .action-buttons-container {
            display: flex;
            justify-content: space-evenly;

            .action-buttons-content {
                width: 40%;
            }
        }
    }
}

.ui.modals > .ui.edit-addon-modal {
    border-radius: 10px;
    padding-bottom: 2em;

    .icon {
        color: $color-green-dark;
    }

    .content {
        .ui.image {
            width: 100px;
        }

        .ui.header {
            margin-top: 0;
        }

        .create-subscription {
            strong {
                color: $color-green-dark;
            }
        }
    }

    .actions {
        background-color: $white;
        border: none;

        .action-buttons-container {
            display: flex;
            justify-content: space-evenly;

            .action-buttons-content {
                width: 40%;
            }
        }
    }
}

.ui.modals > .ui.modal.reset-modal {
    border-radius: 10px;

    .content {
        .ui.header {
            margin-top: 0;
        }

        p.text-info {
            font-size: 1.2em;
            text-align: left;
        }

        p {
            font-size: 1em;
            text-align: left;
        }
    }

    .actions {
        background-color: $white;
        border: none;

        .action-buttons-container {
            display: flex;
            justify-content: space-evenly;

            .action-buttons-content {
                width: 40%;
            }
        }
    }
}

.ui.modals > .ui.modal.sendgrid-modal {
    .content {
        p {
            font-size: 1.4em;
        }
    }

    .actions {
        background-color: $white;
        border: none;

        .action-buttons-container {
            display: flex;
            justify-content: space-evenly;

            .action-buttons-content {
                width: 40%;
            }
        }
    }
}

.ui.modals > .image-preview-modal {
    width: auto;

    .close.icon {
        font-size: 2em;
        color: $white;
    }

    .content {
        padding: 0 !important;

        img {
            margin: 0;
            padding: 0 !important;
            max-height: 85vh;
        }
    }
}

.ui.modals > .iframe-modal {
    .content {
        padding: 0 !important;

        iframe {
            width: 100%;
            height: 80vh;
        }
    }
}

.action-buttons-content-otp {
    width: auto;
}
.input_otp {
    height: 30px !important;
    outline: 0;
    border-width: 0 0 4px;
    border-color: #3f5e2b4d;
    margin-bottom: 30px;
    display: inline-flex;
    margin-left: 10px;
    text-align: center;
    color: #000;
    font-size: 19px;
}
.input_otp_validate {
    height: 30px !important;
    outline: 0;
    border-width: 0 0 4px;
    border-color: #3f5e2b;
    margin-bottom: 30px;
    display: inline-flex;
    margin-left: 10px;
    text-align: center;
    color: #000;
    font-size: 19px;
}

.input_email2 {
    height: 30px;
    height: 30px !important;
    outline: 0;
    border-width: 0 0 4px;
    border-color: #3f5e2b4d;
    width: 60%;
    margin-top: 30px;
    display: inline-flex;
    margin-left: 10px;
    text-align: center;
    margin-left: 20%;
    color: #000;
    font-size: 19px;
    margin-bottom: 35px;
}

@media (max-width: 600px) {
    // .action-buttons-content-otp{
    //     width: 71%;
    // }
    .input_otp {
        text-align: center;
    }
    .input_otp_validate {
        text-align: center;
    }
}

@media only screen and (device-width: 375px) {
    // .action-buttons-content-otp{
    //     width: 61%;
    // }
    .input_otp {
        text-align: center;
    }
    .input_otp_validate {
        text-align: center;
    }
}
