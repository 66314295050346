@import "./variables";
@import "./utilities";

.card-overlay-upgrade {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 2;
    cursor: pointer;
}

.improve-indicator {
    margin: auto;
    width: 100%;
    height: 100%;
    padding: 10px 10px 6px 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: transparent;
    &:hover {
        background-color: rgba(255, 255, 255, 0.8);
        color: $color-green-dark;
        font-size: 1.1em;
        box-shadow: 0px 0px 10px 0px $color-cta;
        font-weight: bold;
    }
}

.card-container {
    margin-top: 2em;

    .bd-text {
        max-width: 250px;
        margin: auto 4em;
        text-align: center;
    }

    .ui.card.boxes-card.disabled-box {
        .box-image-section {
            img {
                opacity: 0.2;
            }
        }
    }

    .ui.card.boxes-card {
        width: 260px;
        border-radius: 10px;
        margin: 1.5em 4em;
        &.selected {
            border: 1px $color-cta solid;
            box-shadow: 0px 0px 10px 0px $color-cta;
        }

        .flex-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .card-image-event {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            width: 100%;

            .ui.image {
                width: 30%;
            }

            .description {
                text-align: left;
                max-width: 50%;
                line-height: 15px;
            }
        }

        .box-image-section {
            height: 10em;

            img {
                height: 12em;
                width: auto;
            }
        }

        .image-logo {
            max-height: 40px;
            object-fit: contain;
        }

        .upgrade-offer {
            display: flex;
            align-items: center;
            text-align: center;
            position: absolute;
            line-height: 1;
            padding-top: 5px;
            top: -32px;
            left: -32px;
            border-radius: 50% !important;
            border: none;
            width: 65px;
            height: 65px;
            background-color: $color-green-dark;
            color: $white;
            z-index: 1;
        }

        .date-text {
            width: 85%;
            margin: 0.8em 0;
            text-align: center;
            min-height: 4em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 1em;

            .link {
                color: $red;
                text-decoration: underline;
                cursor: pointer;
            }

            .charge-info {
                font-size: 0.8em;
                color: $red;
            }
        }
    }
}
