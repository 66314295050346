@import "./variables";

.ui.button.default-button {
    font-family: $font-family;
    font-weight: $font-weight-medium;
    font-size: 16px;
    border-radius: 0;
    display: inline-block;
    background-color: $color-green-dark;
    color: $white;
    padding: 13px;
    cursor: pointer;

    &.inverted {
        background-color: transparent;
        border: 1px solid $color-green-dark;
        color: $color-green-dark;
    }

    &.hidden {
        visibility: hidden !important;
    }

    &:hover {
        background-color: $green-dark;
        color: $white;
    }

    &.disabled {
        background-color: $color-light-gray;
        color: $white;
    }
}

.ui.button.social-button {
    font-family: $font-family;
    font-weight: $font-weight-medium;
    font-size: 16px;
    border-radius: 0px;
    display: inline-block;
    color: $white;
    padding: 13px;
    background-color: #3b5998;
    cursor: pointer;

    &.google {
        background-color: #db4a39;
        margin-right: 30px;
    }
}

.ui.button.outline-button {
    font-family: $font-family;
    font-weight: $font-weight-medium;
    font-size: 16px;
    border-radius: 0.5em;
    display: inline-block;
    background-color: transparent;
    color: $color-light-gray;
    padding: 13px;
    margin: 10px 5px;
    cursor: pointer;
}

.ui.button.outline-button:hover {
    color: $color-cta;
    background-color: transparent;
}

.ui.button.back-button {
    font-family: $font-family;
    font-weight: $font-weight-medium;
    font-size: 16px;
    border-radius: 0.5em;
    display: inline-block;
    background-color: transparent;
    color: #d2142d;
    padding: 13px;
    margin: 5px;
    cursor: pointer;

    &:hover {
        color: $red-dark;
        background-color: transparent !important;
    }
}

.ui.button.default-button.dark {
    background-color: $color-green-dark;
    color: $white;
}
.ui.button.default-button.dark:hover {
    background-color: $color-green-dark;
    color: $white;
}

.ui.button.default-button.inverted:hover {
    background-color: $color-green-dark;
    color: $white;
}

.link-container-padding {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        padding-top: 0.1em;
        margin: 0;
    }

    .ui.button.link-button {
        background-color: transparent;
        border: none;
        text-decoration: underline;
        padding: 0;
        margin: 0.4em;
        font-size: 1.25em;
        color: $color-cta;
        width: auto;
        cursor: pointer;

        &.dark {
            color: $color-dark;
            font-weight: 400;
        }

        &.text-small {
            font-size: 1.3em !important;
        }
    }

    .ui.button.link-button:hover {
        color: $color-cta-light;
    }
}

.link-container-padding.flex-start {
    justify-content: left;
    .ui.button.link-button {
        color: $black;
    }
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
    .ui.button {
        font-size: 12px !important;
    }
}
