@import "./variables";

.rc-calendar {
    width: 100%;

    .rc-calendar-panel {
        .rc-calendar-decade-panel,
        .rc-calendar-year-panel,
        .rc-calendar-month-panel,
        .rc-calendar-date-panel {
            .rc-calendar-decade-panel-header,
            .rc-calendar-year-panel-header,
            .rc-calendar-month-panel-header,
            .rc-calendar-date-panel,
            .rc-calendar-header {
                .rc-calendar-decade-panel-prev-century-btn,
                .rc-calendar-decade-panel-next-century-btn,
                .rc-calendar-year-panel-prev-decade-btn,
                .rc-calendar-year-panel-next-decade-btn,
                .rc-calendar-month-panel-prev-year-btn,
                .rc-calendar-month-panel-next-year-btn,
                .rc-calendar-prev-year-btn:hover,
                .rc-calendar-prev-month-btn:hover,
                .rc-calendar-next-month-btn:hover,
                .rc-calendar-next-year-btn:hover {
                    color: $color-green-dark;
                }
                .rc-calendar-year-panel-decade-select,
                .rc-calendar-month-panel-year-select,
                .rc-calendar-my-select {
                    .rc-calendar-year-panel-decade-select-content,
                    .rc-calendar-year-panel-decade-select-arrow,
                    .rc-calendar-month-panel-year-select-content,
                    .rc-calendar-month-panel-year-select-arrow,
                    .rc-calendar-year-select:hover,
                    .rc-calendar-month-select:hover,
                    .rc-calendar-day-select:hover {
                        color: $color-green-dark;
                    }
                }
            }
            .rc-calendar-decade-panel-body,
            .rc-calendar-year-panel-body,
            .rc-calendar-month-panel-body,
            .rc-calendar-body {
                .rc-calendar-decade-panel-table,
                .rc-calendar-year-panel-table,
                .rc-calendar-month-panel-table,
                .rc-calendar-table {
                    .rc-calendar-decade-panel-tbody,
                    .rc-calendar-year-panel-tbody,
                    .rc-calendar-month-panel-tbody,
                    .rc-calendar-tbody {
                        tr {
                            .rc-calendar-decade-panel-selected-cell {
                                .rc-calendar-decade-panel-decade {
                                    background-color: $color-green-dark;
                                }
                            }
                            .rc-calendar-year-panel-selected-cell {
                                .rc-calendar-year-panel-year {
                                    background-color: $color-green-dark;
                                }
                            }
                            .rc-calendar-month-panel-selected-cell {
                                .rc-calendar-month-panel-month {
                                    background-color: $color-green-dark;
                                }
                            }
                            .rc-calendar-selected-day {
                                .rc-calendar-date {
                                    background-color: $color-green-dark;
                                    color: $white;
                                    border-radius: 20px;
                                }
                            }
                            .rc-calendar-today {
                                .rc-calendar-date[aria-selected="true"] {
                                    color: $white;
                                }
                            }
                            .rc-calendar-today {
                                .rc-calendar-date {
                                    border-color: $color-green-dark;
                                    color: #666;
                                }
                            }

                            td {
                                .rc-calendar-decade-panel-decade:hover {
                                    background-color: rgba(120, 165, 91, 0.6);
                                    color: $white;
                                    border: none;
                                }
                                .rc-calendar-year-panel-year:hover {
                                    background-color: rgba(120, 165, 91, 0.6);
                                    color: $white;
                                    border: none;
                                }
                                .rc-calendar-month-panel-month:hover {
                                    background-color: rgba(120, 165, 91, 0.6);
                                    color: $white;
                                    border: none;
                                }
                                .rc-calendar-date:hover {
                                    background-color: rgba(120, 165, 91, 0.6);
                                    color: $white;
                                    border: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.input-content-calendar {
    height: 3em;
    margin-bottom: 2em;
    position: relative;

    .label {
        background-color: $white;
        padding: 0;
        margin: 10px 0;
        font-size: $font-size-label;
        font-weight: 500;
    }

    .label.floating {
        top: -0.5em;
        left: 2em;
        padding: 0 0.5em;
        z-index: 1;
    }

    .select-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .select-item {
            outline: none;
            background-color: $white;
            display: block;
            font-size: 16px;
            font-family: sans-serif;
            font-weight: 700;
            color: $color-gray;
            line-height: 1.3;
            padding: 0.6em 1.4em 0.5em 0.8em;
            width: 100%;
            max-width: 100%;
            box-sizing: border-box;
            margin: 0;
            border: none;
            border: 1px solid rgba(34, 36, 38, 0.15);
            border-radius: 8px;
            height: 3em;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background-color: $white;
            background-image: url("../../images/drop-down-arrow.svg");
            background-repeat: no-repeat, repeat;
            background-position: right 0.7em top 50%, 0 0;
            background-size: 0.8em auto, 100%;

            &.normal {
                width: 40%;
            }

            &.small {
                width: 28%;
            }

            &::-ms-expand {
                display: none;
            }

            &:hover {
                border-color: rgba(34, 36, 38, 0.35);
            }

            &:focus {
                border-color: $color-light-gray;
                color: $color-dark;
                outline: none;
            }

            .select-option {
                padding: 10px;
                font-size: 1.2em;
                font-weight: normal;
                line-height: 4px;
            }
        }
    }

    *[dir="rtl"] .select-item,
    :root:lang(ar) .select-item,
    :root:lang(iw) .select-item {
        background-position: left 0.7em top 50%, 0 0;
        padding: 0.6em 0.8em 0.5em 1.4em;
    }

    /* Disabled styles */
    .select-item:disabled,
    .select-item[aria-disabled="true"] {
        color: graytext;
        background-image: url("../../images/drop-down-arrow.svg"),
            linear-gradient(to bottom, $white 0%, $color-light-gray 100%);
    }
    .select-item:disabled:hover,
    .select-item[aria-disabled="true"] {
        border-color: $color-light-gray;
    }
}
